.App {
  text-align: center;
  color: black !important;
}

.hero {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.App-header {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: linear-gradient(to right, rgba(117, 112, 255, 0.1), rgba(255, 255, 255, 0.7)),
    url("../src/assets/map.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
.landing-body {
  background-image: linear-gradient(to bottom, transparent, white, rgb(225, 223, 247));
  padding-bottom: 100px;
}

.App-link {
  color: black !important;
}
