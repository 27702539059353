body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fbfa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.footer {
  background-color: rgb(25, 20, 80);
}
.appBar {
  background-color: rgb(237, 236, 250) !important;
  height: 100px;
  box-shadow: none !important;
  color: #100a55 !important;
  align-content: center;
  display: flex;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=Public+Sans:ital,wght@0,200;1,100&display=swap");
